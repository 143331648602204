<template>
    <modal name="forget-password" height="auto" width="520px" :pivotY="0.3" :clickToClose="false" :adaptive="true">
        <h2 class="title"> Réinitialiser votre mot de passe </h2>
        <div class="ui form" id="form" style="margin: 10px 20px;">
            <div class="required field">
                <label class="label"> Entrer votre adresse e-mail </label>
                <input type="email" v-model.trim="user_info.email" placeholder="Adresse e-mail"/>

                <!-- error message -->
                <div class="ui negative message" v-if="$v.user_info.$error">
                    <p v-if="!$v.user_info.email.required">
                        <i class="info circle icon"/>
                        E-mail est obligatoire
                    </p>
                    <p v-else>
                        <i class="info circle icon"/>
                        E-mail invalide
                    </p>
                </div>
            </div>

        </div>
        <div class="ui two bottom attached buttons action-buttons">
            <button class="ui green basic white button" :class="{loading: isBusy}" @click="init" :disabled="isBusy">
                Envoyer
            </button>
            <button class="ui red basic button" :disabled="isBusy" @click.prevent="$modal.hide('forget-password')">
                Annuler
            </button>
        </div>
    </modal>
</template>

<script>
    import {email, required} from 'vuelidate/lib/validators'

    export default {
        name: "forget-password",
        data() {
            return {
                user_info: {
                    email: null
                },
                isBusy: false
            }
        },
        validations: {
            user_info: {
                email: {required, email}
            }
        },
        methods: {
            init() {
                if(this.isBusy)
                    return;

                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                this.isBusy = true
                this.$store.dispatchAsync(this.$SHARED.services.etudiant.generateToken, this.user_info, false, false).then(data => {
                    this.$modal.hide('forget-password')
                    this.$toast.success(this.$SHARED.messages.user.reset_password, {duration: 15000})
                    this.clearFields()
                }, code => {
                    this.$toast.error(this.$SHARED.messages[code])

                }).finally(() => this.isBusy = false )
            },
            clearFields() {
                this.user_info = {
                    email: null
                }
            }
        }
    }
</script>

<style scoped>
    .title {
        text-align: center;
        font-size: 1.8em;
        line-height: 68px;
        text-transform: uppercase;
        border-radius: 10px;
        padding: 0.1em 0.4em;
        color: var(--main-color-blue);
        margin: 0;
        font-weight: 700;
    }

    .label {
        font-size: 14px !important;
    }

    .action-buttons {
        margin-top: 30px !important;
    }
</style>
